// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
}

h1.quote {
	display: flex;
	flex-flow: column;
	gap: 0;
	@include media-breakpoint-down(lg) {
		font-size: 1.35rem;
		margin-top: 0.55rem;
	}
	&::before {
		content: '"';
		font-family: $font-family-tertiary;
		font-size: 100px;
		color: $gold;
		clear: both;
		max-height: 75px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 0.55rem;
		}
	}
}

.subtitle {
	text-transform: uppercase;
	font-family: $font-family-primary;
	font-size: $font-size-base;
	font-weight: 700;

	&::after {
		content: "";
		display: block;
		height: 4px;
		width: 150px;
		background: linear-gradient(to right, $gold-dark, $gold-light);
		margin-top: 1rem;
		@include media-breakpoint-down(lg) {
		}
	}
}
