.image-collection {
	.gallery-grid {
		.gallery-item {
			width: auto;
			max-width: 33.33%;
			flex: auto;

			.gallery-img {
				width: auto;
			}
			.gallery-link {
				.gallery-caption {
					display: none;
				}
				picture {
					img.gallery-img {
						border-radius: 4px;
					}
				}
			}
			&:hover {
				.gallery-caption {
					background: rgba(#000, 0.3);
				}
			}
			@include media-breakpoint-down(lg) {
				max-width: 100%;
				flex: 100%;
				height: auto;
			}
		}
	}
}
