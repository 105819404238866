// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $dark;
	transition: 0.5s;
	box-shadow: $shadow;
	border-bottom: 1px solid $gold;
	&.sticky {
		box-shadow: $shadow;
		.top .column {
			padding: 10px 20px 0 20px !important;
		}
		@include media-breakpoint-down(lg) {
			transform: none;
		}
		.logo {
			transform: rotate(360deg);
			transition: 0.45s ease;
			margin: 0;
			img {
				width: 30px;
				transition: 0.25s ease;
			}
		}
	}

	> [class*="container-"] {
		.container-holder {
			> .column {
				@extend .align-items-center;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.top .column {
			display: flex;
			justify-content: space-between;
			padding: 0.75rem 30px 0.5rem 30px;
			transition: 0.35s;
			height: auto;
			max-height: 50vh;
			@include media-breakpoint-down(lg) {
				justify-content: flex-start;
				padding: 0;
			}
			> div {
				min-width: 10rem;
				min-height: 1rem;
				@include media-breakpoint-down(lg) {
					min-width: unset !important;
					min-height: unset !important;
				}
			}
		}
		&.bottom .column {
			padding: 0.5rem;
			@include media-breakpoint-down(lg) {
				padding: 0;
			}
		}
	}

	// social icons header
	.header-social {
		gap: 0.65rem;
		justify-content: flex-end;
		.list-item-title {
			display: none;
		}
		i {
			color: $gold;
			font-size: 20px;
			transition: 0.35s;
			&:hover {
				transform: scale(1.15);
				transition: 0.35s;
			}
			@include media-breakpoint-down(lg) {
				font-size: 16px;
			}
		}
	}

	// menu

	// menu-toggle
	.menu-toggle {
		.navbar-toggler i {
			transition: 0.35s ease;
			color: $white;
			font-size: 24px;
			font-weight: 200;
			max-width: 24px;
			width: 24px;
			min-width: 24px;
		}
		.navbar-toggler {
			&[aria-expanded="true"] {
				i {
					transition: 0.35s ease;
					transform: rotate(180deg);
					max-width: 24px;
					width: 24px;
					min-width: 24px;
					font-weight: 200;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		display: flex;
		justify-content: center;
		margin: 0.75rem 0 10px 0;

		transition: 0.45s ease;
		a {
			@extend .d-block;

			img {
				width: 87px;
				&:hover {
					filter: brightness(1.15);
					transition: 0.35s ease;
				}
				@include media-breakpoint-down(lg) {
					width: 60px;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			margin-left: 2.5px;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			.navbar-nav {
				gap: 1rem;
				padding: 1.5rem 1.15rem;

				.nav-item {
					.nav-link {
						color: $white;
						font-weight: 700;
						text-transform: uppercase;
						font-family: $font-family-secondary;
						display: flex;
						align-items: center;
						gap: 0.5rem;
						flex-flow: row;
						width: 100%;
					}
					&.active {
						.nav-link {
							&::before {
								background: $gold;
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			display: flex;
			justify-content: center;
			.navbar-nav {
				gap: 1rem;
				text-transform: uppercase;
				li {
					font-size: $font-size-base;
				}
			}
		}

		.dropdown-menu.show {
			background-color: $dark !important;
			@include media-breakpoint-down(lg) {
				transform: none !important;
				position: static !important;
				* {
					font-family: $font-family-primary !important;
					font-size: 0.85rem;
				}
			}
			border: none;
			&::after,
			&::before {
				display: none;
			}
			.nav-item {
				padding: 0.65rem;
				&:hover > * {
					color: $gold;
				}
			}
		}
	}

	.sub {
		display: flex;
		flex-flow: row-reverse;
		gap: 1rem;
		padding-right: 0.35rem;
		@include media-breakpoint-down(lg) {
			flex-flow: row-reverse;
			gap: 0.45rem;
			width: max-content;
			justify-self: flex-end;
			align-items: center;
			margin-right: 0;
			margin-left: auto;
		}
	}
	// site-switcher
	.site-switcher {
		display: flex;
		gap: 0.65rem;
		justify-content: flex-end;
		color: $gold;
		* {
			color: $gold;
		}
		li {
			transform: translateY(-1px);
			&:hover {
				border-color: $gold !important;
			}
		}
		img {
			max-width: 20px;
		}
		@include media-breakpoint-down(lg) {
			img {
				max-height: 15px;
			}
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: sticky;
		}
	}
}
