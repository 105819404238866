&.modal-open {
	overflow: unset;
}

.modal {
	pointer-events: none;

	.modal-dialog {
		pointer-events: none;
		max-width: unset !important;
		position: fixed;
		bottom: 0px;
		right: 20px;

		.modal-content {
			background: $black;
			color: $white;
			border: 1px solid $gold;
			border-radius: 8px;
			width: fit-content;
			min-width: 385px !important;
			margin-right: 5%;
			text-align: center;
			box-shadow: 0 3px 6px rgba($black, 0.15);

			@include media-breakpoint-down(lg) {
				min-width: unset !important;
				margin-right: unset !important;
			}

			.modal-header {
				border-bottom: 0 !important;
				height: 0;
				padding: 0 1rem !important;

				.modal-title {
					display: none;
				}

				.close {
					margin: 0 -1rem -1rem auto !important;
					z-index: 1000;

					span {
						color: $white !important;
					}
				}
			}

			.modal-body {
				h5 {
					color: $gold;
				}
			}

		}
	}

	@include media-breakpoint-down(sm) {
		display: none !important; // hide on mobile
	}
}

.modal-backdrop {
	display: none;
	pointer-events: none;
}
