main {
	padding-bottom: 360px;
	@include media-breakpoint-down(lg) {
		padding-bottom: 190px;
	}
}

// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}
// news-section
.news-section {
	overflow: hidden;
	padding-top: 30px;
	padding-bottom: 6vh;
	background: linear-gradient(135deg, rgba($black, 1) 0%, rgba($black, 1) 35%, rgba($gold, 1) 100%);

	.slider {
		.owl-item {
			.card {
				width: 100%;
				height: 85vh !important;
				border-color: $gold;
				.card-description {
					display: none;
				}
				.card-title {
					font-size: 40px !important;
				}
			}
		}
		.owl-nav {
			display: none;
		}
		@include media-breakpoint-down(lg) {
			padding-left: 1rem;
			padding-right: 1rem;
			.owl-dot span {
				max-width: 13px;
				max-height: 13px;
			}
			.card-title {
				font-size: 30px !important;
			}
		}
	}

	.logo-visual {
		margin: 0 auto 30px auto;
	}
}

// banner section
.banner-section {
	background-color: $dark;
}
.banner-section .column.default {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

// shop section
.shop-section {
	padding: 6vh 0 6vh 0;
	background: $dark;
	border-bottom: 1px solid $gold;
	overflow-y: visible;
	overflow-x: hidden;
	z-index: 999;
	@include media-breakpoint-down(lg) {
		padding: unset;
		padding-bottom: 6vh;
		padding-left: 15px;
		padding-right: 15px;
		z-index: 990;
		.subtitle {
			font-size: 13px !important;
			font-weight: 600 !important;
		}
	}
	.hatseflats-logo img {
		margin-top: 1rem;
	}

	.shop-slider {
		display: none;
	}

	// uncomment onderstaande mocht shop slider toch wel geplaatst moeten worden

	// .shop-slider {
	// 	padding: 3vh 0 3vh 0;
	// 	background: linear-gradient(rgba(#fff, 0), rgba($dark, 0.95) 50%);

	// 	.owl-item {
	// 		background-color: #fff;
	// 		.item {
	// 			aspect-ratio: 1/1;
	// 			padding: 0;

	// 			background-size: 80%;
	// 			background-position: center center;
	// 		}
	// 		aspect-ratio: 1/1;
	// 		.owl-caption-holder {
	// 			.owl-container {
	// 				padding: 0;
	// 			}
	// 			padding: 0.35rem 1rem 0.35rem 1rem;
	// 			.owl-caption {
	// 				.owl-subtitle {
	// 					&::after {
	// 						all: unset !important;
	// 					}
	// 					all: unset !important;
	// 					order: 1 !important;
	// 					color: #000 !important;
	// 					font-family: $font-family-primary !important;
	// 					font-size: 14px !important;
	// 					font-weight: 600 !important;
	// 				}
	// 				.owl-description {
	// 					display: none;
	// 				}
	// 				.owl-title {
	// 					all: unset !important;
	// 					color: #000 !important;
	// 					font-family: $font-family-primary !important;
	// 					font-size: 14px !important;
	// 					white-space: nowrap !important;
	// 					overflow: hidden !important;
	// 					text-overflow: ellipsis !important;
	// 					max-width: 100% !important;
	// 				}
	// 			}
	// 			.owl-btn {
	// 				// display: none;
	// 				opacity: 0;
	// 				position: absolute;
	// 				left: 0;
	// 				top: 0;
	// 				width: 100%;
	// 				height: 100%;
	// 			}
	// 		}
	// 	}
	// 	@include media-breakpoint-down(lg) {
	// 		.owl-nav {
	// 			display: none !important;
	// 		}
	// 	}
	// }
	.column.two {
		position: relative;
		overflow: visible;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
	}
	.column.one {
		position: relative;
		overflow: visible;
		display: flex;
		flex-flow: column;
		justify-content: center;
	}
	div.shop-btn {
		a {
			@extend .btn, .btn-primary;
			min-height: 89px;
			min-width: 314px;
			font-size: 24px;
			font-weight: 600;
			display: flex;
			flex-flow: row-reverse;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			&::before {
				content: "\f054";
				font-family: $font-awesome;
				display: block;
			}
			@include media-breakpoint-down(lg) {
				min-height: 55px !important;
				max-height: 55px !important;
				font-size: 20px;
			}
		}
	}

	z-index: 900;
}

// social section
.social-section {
	background: $dark;
	padding: 6vh 0;
	@include media-breakpoint-down(lg) {
		padding: 6vh 0 !important;

		z-index: 980;
		.eapps-instagram-feed-posts-slider-next,
		.eapps-instagram-feed-posts-slider-prev {
			display: none;
		}
	}
	.title,
	.social-icons-page {
		display: flex;
		justify-content: center;
	}
	.title {
		@extend .subtitle;
		display: flex;
		flex-flow: column;
		align-items: center;
		* {
			font-size: 40px;
			@include media-breakpoint-down(lg) {
				font-size: 1.75rem;
			}
		}
	}

	.eapps-instagram-feed-posts-slider-nav {
		background: none !important;
	}
	.social-icons-page {
		margin-top: 3vh;
		gap: 1rem;
		li {
			@extend .btn, .btn-primary;
			border-radius: 50%;
			aspect-ratio: 1/1;
			width: 80px !important;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover {
				transform: scale(1.1);
			}
		}
		.list-item-title {
			display: none;
		}
		i {
			font-size: 30px;
			color: $black;
		}
		@include media-breakpoint-down(lg) {
			li {
				width: 48px !important;
				height: 48px !important;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 1px;
				i {
					font-size: 20px;
					padding-top: 3px;
				}
			}
		}
	}
	.eapps-instagram-feed-header-container,
	.eapps-instagram-feed-title-container {
		display: none;
	}
	.eapps-instagram-feed {
		margin: 6vh 0;
	}
	.eapps-instagram-feed-posts-view {
		gap: 1rem;
		justify-content: center;
		.eapps-instagram-feed-posts-item-template-tile {
			border: 1px solid $gold;
			border-radius: 8px;
			width: calc(33% - 3rem) !important;
			@include media-breakpoint-down(lg) {
				border: none !important;
				border-radius: 0px !important;
				width: calc(100% - 3rem) !important;
			}
		}
		@include media-breakpoint-down(lg) {
			position: relative;
			gap: 0 !important;
			&::before {
				content: "@petergillisoostappen";
				width: 100%;
				color: #1a1a1a;
				background: #cdc8cc;
				height: 40px;
				width: calc(100% - 3rem) !important;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 0.55rem;
				font-size: 13px;
			}
		}
	}
}

// shoutout section
.shoutout-section {
	background: $dark;
	padding: 18vh 0;
	.container-holder {
		border: 1px solid $gold;
		border-radius: 8px;
		padding: 2.5rem;
	}
	@include media-breakpoint-down(lg) {
		padding: 12vh 0;
		.container-holder {
			border: 1px solid $gold;
			margin-right: 1rem;
			margin-left: 1rem;
			border-radius: 8px;
			padding: 15px 0;
		}
		h6,
		h5,
		h4,
		h3,
		h2,
		h1 {
			font-weight: 500;
			font-size: 24px;
		}

		p {
			font-weight: 500;
			font-size: 14px;
		}
	}
	* {
		font-family: $font-family-primary !important;
	}
	h6,
	h5,
	h4,
	h3,
	h2,
	h1 {
		font-weight: 500;
	}
	p {
		font-weight: 500;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.btn,
	.btn-primary {
		display: flex;
		width: fit-content;
		min-width: 190px;
	}
	iframe {
		aspect-ratio: 1/1;
		width: 100%;
		height: 100%;
		border-radius: 6px;
	}
	.two {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
	}
}

// lead-section
// =========================================================================
&.home {
	.lead-section {
		padding: 12vh 0;
		.container-one-column {
			.container-holder {
				max-width: 850px;
				margin: 0 auto;
				text-align: center;
				font-size: 16px;
				letter-spacing: 1.315x;
			}
		}
		.subtitle {
			font-size: 50px;
			font-family: $font-family-secondary;
			font-weight: 300;
			text-transform: none;
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 3vh;
			&::after {
			}
		}
		ul.intro-logos {
			width: 100%;
			margin-top: 3vh;
			display: flex;
			gap: 1rem;
			justify-content: center;

			li {
				img {
					max-height: 82px;
					width: auto;
				}

				.list-item-title {
					display: none;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.column.default {
				padding-left: 1.5rem !important;
				padding-right: 1.5rem !important;
				line-height: 1.85rem;
			}
			padding: 6vh 0;
			background: linear-gradient(
				rgba($dark, 1),
				rgba(rgb(75, 75, 75), 1)
			);
			.title {
				display: flex;
				justify-content: center;
				margin-bottom: 1rem;
			}
			.subtitle {
				max-width: 60vw;
				font-size: 30px;
				font-family: $font-family-secondary;
				font-weight: 300;
				text-transform: none;
				text-align: center;
				display: flex;
			}
		}
	}
}
.lead-section {
	padding: 6vh 0 0 0;
	background: linear-gradient(rgba(rgb(75, 75, 75), 1), rgba($dark, 1));
	@include media-breakpoint-down(lg) {
		padding: 6vh 0;
	}
	.container-one-column {
		padding: 7.5px;
		.container-holder {
			margin: 0 auto;
			text-align: left;
			.column {
				padding-left: 7.5px;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	background: $dark;
	padding-bottom: 6vh;
	.column {
		padding-left: 15px;
	}
}
&.home .content-section {
	padding: 6vh 0;
	background: $dark;
	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 6vh 0;
	background: $dark;
	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 6vh 0;
	background: $dark;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
