// eyecatcher
.eyecatcher .owl-carousel .item {
	border-bottom: 1px solid $gold;
}
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	@include media-breakpoint-down(lg) {
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 90vh;
	min-height: 540px;
	max-height: 1080px;
	@include media-breakpoint-down(lg) {
		background-color: #000;
		.owl-item {
			height: 90vh;
		}
		.item {
			max-height: 55vh !important;
			min-height: 55vh !important;
			position: relative;
			overflow: visible;
			.owl-caption-holder {
				position: absolute;
				background: linear-gradient(135deg, rgba($black, 1) 0%, rgba($black, 1) 35%, rgba($gold, 1) 100%);
				height: 35vh;
				bottom: 0;
				left: 0;
				z-index: 990;
				transform: translateY(100%);
				padding: 1.5rem;
				display: flex;
				align-items: center;
				.owl-title,
				.owl-description {
					text-align: center;
				}
				.owl-caption {
					display: flex;
					flex-flow: column;
					align-items: center;
					.subtitle {
						display: flex;
						flex-flow: column;
						align-items: center;
					}
				}
			}
		}
		.owl-prev,
		.owl-next {
			transform: translateY(-22.5vh);
		}
		.owl-dots {
			transform: translateY(-36vh);
			.owl-dot {
				span {
					border-color: $gold;
					background: transparant;
					background-color: none;
				}
				&.active {
					span {
						background: $gold;
					}
				}
			}
		}
	}
}

.banner-section {
	.owl-carousel .item {
		min-height: 85vh;
		padding: unset !important;
		@include media-breakpoint-up(sm) {
			height: 75vh;
		}
		border-top: 1px solid $gold;
		border-bottom: 1px solid $gold;
	}
	@include media-breakpoint-down(lg) {
		background-color: #000;

		.owl-item {
			height: 75vh;
		}

		.item {
			max-height: 40vh !important;
			min-height: 40vh !important;
			position: relative;
			overflow: visible;

			.owl-caption-holder {
				position: absolute;
				background: #000;
				bottom: 0;
				left: 0;
				height: 35vh;
				z-index: 990;
				transform: translateY(100%);
				padding: 0 1.5rem;
				display: flex;
				align-items: center !important;
				.owl-caption {
					display: flex;
					flex-flow: column;
					align-items: center;
					.owl-title,
					.owl-description {
						text-align: center;
					}
					.subtitle {
						display: flex;
						flex-flow: column;
						align-items: center;
					}
				}
			}
		}

		.owl-prev,
		.owl-next {
			transform: translateY(-17.5vh) !important;
		}

		.owl-dots {
			display: none;

			.owl-dot {
				span {
					border-color: $gold;
					background: transparant;
					background-color: none;
				}

				&.active {
					span {
						background: $gold;
					}
				}
			}
		}
	}
}

section.eyecatcher .owl-carousel .item,
.banner-section .owl-carousel .item {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 45%;
		bottom: 0;
		left: 0;
		background: linear-gradient(rgba(#000, 0), rgba(#000, 0.55));
		z-index: 100;
	}
	.owl-caption-holder,
	.owl-dots,
	.owl-nav {
		z-index: 900;
	}
}

.owl-carousel {
	.item {
		.owl-caption-holder {
			height: 100%;
			display: flex;
			align-items: flex-end;
			padding-bottom: 6vh;
			.owl-caption {
				text-align: start;
				display: flex;
				flex-flow: column;
				.owl-subtitle {
					order: -1;
					@extend .subtitle;
				}
				.owl-title {
					font-size: $h1-font-size;
					max-width: 40%;
					line-height: 65px;
					@include media-breakpoint-down(lg) {
						max-width: 85%;
						font-size: 36px;
						line-height: normal;
					}
				}
				.owl-description {
					display: none;
				}
			}
		}
	}
	.owl-item {
		&.active {
			.item {
				@include media-breakpoint-up(xl) {
					animation: bgMove 14s infinite;
					@keyframes bgMove {
						0% { background-position: top left;}
						100% { background-position: right bottom;}
					}
				}
				.owl-caption-holder {
					@include media-breakpoint-up(xl) {
						animation: 1s .25s fadeInUp both;
					}
				}
			}
		}
	}
}
