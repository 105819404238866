.card {
	min-height: 400px;
	background: $dark;
	.card-image,
	.card-img,
	.card-image-link,
	picture {
		height: 100%;
	}
	.card-img {
		object-fit: cover;
		position: relative;
	}
	.card-image {
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			background: linear-gradient(rgba(#000, 0), rgba(#000, 0.45));
			height: 40%;
			width: 100%;
		}
	}
	.card-body {
		position: absolute;
		display: flex;
		flex-flow: column;
		height: 100%;
		justify-content: flex-end;
		.card-caption {
			display: flex;
			flex-flow: column;
			justify-content: flex-end;
			padding: 1rem;
			.card-subtitle {
				order: -1;
				@extend .subtitle;
			}
			.card-title {
				font-size: $h3-font-size;
			}
		}
	}
	div.card-buttons {
		padding: 1rem;
		.btn {
			min-width: 190px;
			min-height: 42px;
			align-items: center;
			display: flex;
			width: max-content;
			justify-content: center;
		}
	}
	.card-title {
		font-size: $h4-font-size !important;
	}
	.card-description-content {
		display: none;
	}
}

// image grid

.collection.grid {
	.card-image {
		&::after {
			display: none !important;
		}
	}
	.card-img-overlay {
		background: none !important;
	}
}

// news cards
&.blog-post-overview {
	article.item {
		max-width: 100% !important;
		flex-basis: 100% !important;
		padding-left: 0 !important;
	}
	.card {
		min-height: unset !important;
		.card-image {
			height: unset !important;
			aspect-ratio: 1/1;
			object-fit: cover !important;
		}

		.card-body {
			position: relative;
			background: rgba(#fff, 0.1);
		}

		.card-subtitle {
			&::after {
				margin-top: 0.45rem !important;
			}
		}

		.card-caption {
			justify-content: unset !important;
		}

		.card-description-content {
			width: 100%;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			line-clamp: 5;
			-webkit-box-orient: vertical;
		}
	}
}
