// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$white: #fff;
$black: #231F20;
$dark: #282828;
$gold: #C5A862;
$gold-dark: #D89D3C;
$gold-light: #F1C276;
$grey: #A8A8A8;

// theme-colors
$primary: $gold;
$secondary: $gold-dark;
$tertiary: $gold-light;

// body
$body-bg: $black;
$body-color: $white;

// links
$link-color: $white;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coustard:wght@400;900&display=swap');
$font-family-primary: 'Montserrat', sans-serif;
$font-family-secondary: 'Playfair Display', serif;
$font-family-tertiary: 'Coustard', serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 0.875rem; // 14px

$h1-font-size: $font-size-base * 4.28; // 60px
$h2-font-size: $font-size-base * 3.56; // 50px
$h3-font-size: $font-size-base * 2.85; // 40px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 300;
$headings-color: $white;

// contextual
$light: $white;
$dark: $dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;
$matrix-color-tertiary: $tertiary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
