// btn
.btn {
	border:none;
	font-weight: 700;
	overflow: hidden;
	position: relative;
width: max-content;
	// btn-primary
	&.btn-primary {
		background: linear-gradient(to right, $gold-dark, $gold-light);
		&::after {
			content: "";
			width: 10%;
			height: 100%;
			position: absolute;
			left: -20%;
			background: $gold-light;
			filter: blur(3px);
		}
		&:hover {
			&::after {
				left: 100%;
				width: 20%;
				opacity: 0.5;
				transition: 0.5s ease-out;
			}
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
