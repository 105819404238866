.family-section {
	border-top: 1px solid $gold;
	border-bottom: 1px solid $gold;
	background: #d1cfd2;
	overflow-y: hidden;
	position: relative;
	height: 70vh;
	.family-member-overview {
		position: absolute;
		height: 70vh;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		left: 50%;
		transform: translateX(-52%);
		transition: 1s ease;
		&.hide {
			opacity: 0;
		}
		&::after {
			content: "";
			display: block;
			background: #000;
			color: #fff;
			border: 1px solid $gold;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 4px;
			padding: 0.5rem 1rem;
			font-weight: 600;

			font-family: $font-family-secondary;
		}
		&:nth-child(2) {
			&::after {
				content: "Mark Gillis";
				top: 47%;
				left: 10%;
			}
		}
		&:nth-child(3) {
			&::after {
				content: "Peter Gillis";
				top: 45%;
				left: 50%;
				transform: translateX(-25%);
			}
		}
		&:nth-child(4) {
			&::after {
				content: "Ruud Gillis";
				top: 47%;
				right: 5%;
				left: auto;
			}
		}
		&:nth-child(5) {
			&::after {
				content: "Wendy van Hout";
				top: 65%;
				left: 25%;
			}
		}
		&:nth-child(6) {
			&::after {
				content: "Inge Gillis";
				top: 65%;
				right: 20%;
				left: auto;
			}
		}
	}
	.family-member {
		opacity: 0;
		pointer-events: none;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-52%);
		transition: 1s ease;
		.info {
			display: flex;
			justify-content: center;
			padding: 1.5rem;

			* {
				color: $black;
			}

			.title {
				@extend .subtitle;
				text-transform: none !important;

				&::after {
					margin-top: 2px !important;
				}

				margin-bottom: 1rem;
			}

			a.family-back-btn {
				text-decoration: none;
				color: black;
				font-weight: 600;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				gap: 0.35rem;

				&::before {
					content: "\f053";
					font-family: "Font Awesome 5 Pro";
				}

				&:hover {
					&::before {
						transform: translateX(-0.5rem);
					}
				}
			}
		}
		&.selected {
			opacity: 1 !important;
			pointer-events: initial;
			display: flex;
			align-items: flex-end;
			transition: 1s ease;
		}
		.family-member-photo {
			display: flex;
			align-items: flex-end;
			justify-content: center;
		}
		img {
			object-fit: cover !important;
			max-height: 70vh;
		}
	}
	.family-caption {
		position: absolute;
		bottom: 6vh;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
		&.hide {
			opacity: 0;
		}
	}
	&::before {
		content: "";
		background: linear-gradient(rgba(0, 0, 0, 0) 70%, black 120%);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;
		z-index: 1;
	}

	@include media-breakpoint-down(lg) {
		height: 55vh !important;

		.family-member-overview {
			height: 55vh !important;
		}
	}
	@keyframes float {
		0% {
			transform: translate(0, 0);
		}
		50% {
			transform: translate(0, 50%);
		}
		100% {
			transform: translate(0, 0);
		}
	}

	@include media-breakpoint-down(sm) {
		height: 30vh !important;
		.family-member-overview {
			height: 30vh !important;
			&.hide {
				display: none;
			}
			&::after {
				content: "\f06e" !important;
				padding: 2px !important;
				background: rgba(#fff, 0.6) !important;
				aspect-ratio: 1/1 !important;
				width: 1.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: $font-awesome !important;
				color: $gold !important;
				border-radius: 50% !important;
				animation: float 6s ease infinite;
			}
		}
		.family-member {
			height: 75vh !important;
			justify-content: flex-start !important;
			display: flex !important;
			align-items: flex-start !important;
			padding: 5rem 2.5rem;
		}
		.family-caption {
			display: none;
		}
		.info {
			height: 100% !important;
		}
		.family-member-photo {
			display: none !important;
		}
		&.grow {
			min-height: 75vh;
			display: flex;
			align-items: center;
		}
		&::before {
			display: none;
		}
	}

	.mouse {
		display: block;
		position: fixed;
		border-radius: 50%;
		aspect-ratio: 1/1;
		width: 1.5rem;
		background: white;
		left: -1rem;
		top: -1rem;
		transition: 0s !important;
		z-index: 1000;
		pointer-events: none;
		transform: translate(-50%, -50%);
		@include media-breakpoint-down(lg) {
			display: none;
		}
		&.clicked {
			border-radius: 25%;
		}
	}
}
