// footer
.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: -1;
	background: #000;
	padding: 60px 0;
	@include media-breakpoint-down(lg) {
		padding: 0;
	}
	@include media-breakpoint-up(xl) {
		min-height: 360px;
	}
	.logo {
		display: flex;
		justify-content: center;
		padding: 60px 0;
		img {
			width: 120px;
			transition: 0.35s ease;
			&:hover {
				filter: brightness(1.15);
				transition: 0.35s ease;
			}
			@include media-breakpoint-down(lg) {
				max-width: 69px;
			}
		}
	}
}
